<template>
<div v-if="perms.includes('Facturation Fournisseurs') || perms.includes('Admin')">
  <section class="invoice-add-wrapper">
     <validation-observer
        ref="formdata"
         >
         <!-- #default="{invalid}" -->
   <b-form @submit.prevent id="formdata">   
     <b-row class="invoice-add">
     
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <!-- <b-form @submit.prevent id="formdata"> -->
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <h3 class="text-primary invoice-logo">
                      Facture Fournisseur
                    </h3>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Facture N°
                    </h4>
                    <validation-provider
                        #default="{ errors }"
                        name="Numéro Facture"
                        rules="required">
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      
                      <!-- <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                       -->
                      <b-form-input
                        id="invoice-data-id"
                        v-model="NumFact"
                        placeholder="11/1111"
                        :state="errors.length > 0 ? false:null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                        
                    </b-input-group>
                    </validation-provider>
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date:
                    </h4>
                    <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required">
                          <b-form-input
                          v-model="dateFact"
                          type="date"
                          class="form-control invoice-edit-input"
                          :state="errors.length > 0 ? false:null" />
                        
                    <!-- <flat-pickr
                      format="MM/DD/yyyy"
                      v-model="dateFact"
                      class="form-control invoice-edit-input"
                      :state="errors.length > 0 ? false:null" /> -->
                 
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div> -->
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <!-- <hr class="invoice-spacing"> -->

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row >

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="12"
                  class="mb-lg-1"
                >
                  <h6 class="mb-3">
                    Choisir le fournisseur:
                  </h6>

                  <!-- Select Client -->
                  <validation-provider
                        #default="{ errors }"
                        name="Fournisseur"
                        rules="required">
                  <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="fournis"
                    label="NomSociete"
                    input-id="invoice-data-client"
                    :clearable="false"
                    @input="getProduct(invoiceData.client.id)"
                    :state="errors.length > 0 ? false:null" 
                  >
                
                    <template #list-header>
                        <!-- v-b-toggle.sidebar-invoice-add-new-customer -->
                      <li
                        @click="addfourni()"
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Ajouter nouveau fournisseur</span>
                      </li>
                    </template>
                  </v-select>                
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.NomSociete }}
                    </h6>
                    <b-card-text class="mb-25">
                       {{ invoiceData.client.Ice }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.adresse }}, Maroc
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.phone }}
                    </b-card-text>
                    <!-- <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text> -->
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            
                     <b-card-body class="invoice-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                  <!-- :style="{height: trHeight}" -->

                    <b-row
                      v-for="(item, index) in FactureData.produits"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">

                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              Produit
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              Prix Achat
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Quantité
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Total H.T
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              <label class="d-inline d-lg-none">Produit</label>
                              <!--  v-model="item.Nom" -->
                              <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                               
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="Products"
                                label="Nom"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Choisir Produit"
                                @input="val => updateItemForm(index, val)"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              <label class="d-inline d-lg-none">Prix Achat</label>
                              <b-form-input
                                v-model="item.Prix_Achat"
                                type="number"
                                class="mb-2"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Quantité</label>
                              <validation-provider
                                #default="{ errors }"
                                name="Quantité"
                                rules="required|integer|min_value:1">
                              <b-form-input
                                v-model="item.qnt"
                                type="number"
                                class="mb-2"
                                :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total H.T</label>
                              <p class="mb-1" v-if="item.qnt!=''">
                                {{ item.Prix_Achat * item.qnt }} DH
                              </p>
                              <p v-else class="mb-1" >
                                0 DH
                              </p>
                            </b-col>
                           
                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                        
                           
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm" >
                    Ajouter Produit
                  </b-button>
                </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
            
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1">
                <b-card-body class="pt-0">
                 <span class="font-weight-bold">Total H.T: </span>
                  <validation-provider
                      #default="{ errors }"
                      name="totalHT"
                      rules="required|integer|min_value:1">
               <b-form-input
                    v-model="totalHT"        
                    type="number"
                    class="mb-2"
                    :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class=" pt-0">
                <span class="font-weight-bold">TVA : </span>
                <validation-provider
                      #default="{ errors }"
                      name="TVA"
                      rules="required|integer|min_value:1">
                      <select class="form-control" style="margin-bottom: 20px;" v-model="tva" @change="getNet(tva)"
                        :state="errors.length > 0 ? false:null">
                          <option value="7">7%</option>
                          <option value="10">10%</option>
                          <option value="14">14%</option>
                          <option value="20">20%</option>
                      </select>
               <!-- <b-form-input
                    v-model="tva"            
                    type="number"
                    class="mb-2"
                    @input="getNet()"
                    :state="errors.length > 0 ? false:null" /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                 </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">NET A PAYER TTC: </span>
                 <validation-provider
                      #default="{ errors }"
                      name="totalNET"
                      rules="required|min_value:1">
               <b-form-input
                    v-model="totalNET"      
                    class="mb-2"
                    id="numberInput"
                    :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                 </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex "
                  order="2"
                  order-md="1"
                >
                    <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">La présence facture a la somme de  </span>
              <b-form-textarea id="result" v-model="somme" placeholder="CINQ MILLE DHS" />
            </b-card-body>
                 </b-col>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <!-- <label
                    for="invoice-data-sales-person"
                    class="font-weight-bold mr-50"
                  >Mode de reglement :</label> -->
                  <b-card-body class="invoice-padding pt-0">
                   <span class="font-weight-bold">Mode de reglement :  </span>
                  <b-form-input
                    id="invoice-data-sales-person"
                    placeholder="Espece/Cheque"
                    v-model="reglemment"
                  />
                  </b-card-body>
                </b-col>
               
              </b-row>
            </b-card-body>            
          </b-card>
        <!-- </b-form> -->
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
                <!-- Button: Print -->
           <input type="file"  accept="application/pdf,image/*" class="hidden" ref="updateImgInput" multiple="multiple" @change="imageSelected" >
          <b-button style="margin-bottom: 20px;"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="$refs.updateImgInput.click()"
          >
            Importer Pièces Jointes
          </b-button>
          <b-media-aside>
              <div v-for="(attachment, index) in attachments " :key="index">
                <span class="label label-primary">{{ attachment.name + ' (' + Number((attachment.size / 1024 / 1024).toFixed(1)) + 'MB)'}}<br></span>
              </div>
        </b-media-aside>
          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            
            @click="addFacture()"
            
          >
          <!-- :disabled="invalid" -->
            Ajouter La Facture
          </b-button>

        </b-card>

        
      </b-col>
      
    </b-row>
    </b-form>
     </validation-observer>
  </section>
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
  BMedia, BMediaAside, BMediaBody, BLink,BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import invoiceStoreModule from './invoiceStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email,min_value } from '@validations'
// import { min_value } from 'vee-validate/dist/rules';
// import * as rules from 'vee-validate/dist/rules';
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import NotAuthoriz from '../NotAuthoriz.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,
    flatPickr,
    vSelect,
    Logo,ValidationProvider, ValidationObserver,NotAuthoriz
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data(){
      return{
        perms:[],
          required,
          alphaNum,
          email,
          min_value,
          fournis:[],
          Products:[],
          NumFact:'',
          totalHT:'',
          totalNET:'',
          tva:'',
          somme:'',
          reglemment:'',
          dateFact:new Date().toISOString().slice(0,10),
          fourniId:0,
          image:null,
          imagepreview:null,
          attachments:[],
          UserID:{}


      }
  },
  mounted() {
    this.initTrHeight()
    this.perms=JSON.parse(localStorage.getItem('access'));
      this.$http.get(`${config.API_BASE_URL}/api/auth/getAllFournis`)
      .then(res => { this.fournis = res.data })
      this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
   
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
       imageSelected(e){
            let selectedFiles=e.target.files;
                if(!selectedFiles.length){
                    return false;
                }
                for(let i=0;i<selectedFiles.length;i++){
                    this.attachments.push(selectedFiles[i]);
                }
                // console.log(this.attachments);
         },
      addfourni(){
          this.$router.push('/AddNewFournis');
      },
      getNet(tva){
        console.log(tva);
        console.log(this.tva);
        this.totalNET=0;
        this.totalNET=parseFloat(this.totalHT*(1+this.tva/100)).toFixed(2)
        // console.log(typeof parseFloat(this.totalNET))
        let numberInput = parseFloat(this.totalNET) ;
        //  console.log(typeof numberInput)
        let myDiv = document.querySelector('#result');
        let oneToTwenty = ['','un ','deux ','trois ','quatre ', 'cinq ','six ','sept ','huit ','neuf ','dix ',
        'onze ','douze ','treize ','quatorze ','quatorze ','seize ','dix-sept ','dix-huit ','dix-neuf '];
        let tenth = ['', '', 'vingt','trente','quarante','cinquante', 'soixante','soixante-dix','quatre-vingts','quatre-vingt-dix'];
        if(numberInput.toString().length > 7) return myDiv.innerHTML = 'overlimit' ;
        // console.log(numberInput);
        //let num = ('0000000000'+ numberInput).slice(-10).match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      let num = ('0000000'+ numberInput).slice(-7).match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/);
        // console.log(num);
        if(!num) return;

        let outputText = num[1] != 0 ? (oneToTwenty[Number(num[1])] || `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}` )+' million ' : ''; 
      
        outputText +=num[2] != 0 ? (oneToTwenty[Number(num[2])] || `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}` )+'cent ' : ''; 
        outputText +=num[3] != 0 ? (oneToTwenty[Number(num[3])] || `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`)+' mille ' : ''; 
        outputText +=num[4] != 0 ? (oneToTwenty[Number(num[4])] || `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) +'cent ': ''; 
        outputText +=num[5] != 0 ? (oneToTwenty[Number(num[5])] || `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]} `) : ''; 

        // myDiv.innerHTML = outputText;
        this.somme=outputText;
        // console.log(outputText);
      },
      getProduct(id){
          this.fourniId=id
          this.$http.post(`${config.API_BASE_URL}/api/auth/getProductFourni/${id}`)
      .then(res => { this.Products = res.data })
      },
      AddProd(){
        let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('products', JSON.stringify(this.FactureData.produits));
          data.append('NumFact', this.NumFact);
          data.append('fourniId', this.fourniId);
          data.append('totalHT', this.totalHT);
          data.append('totalNET', this.totalNET);
          data.append('tva', this.tva);
          data.append('somme', this.somme);
          data.append('reglemment', this.reglemment);
          data.append('dateFact', this.dateFact);
          // data.append('dateFact', moment(String(this.dateFact)).format('DD/MM/YYYY'));
           for(let i=0; i<this.attachments.length;i++){
                    data.append('attachments[]',this.attachments[i]);
                }
          this.$http.post(`${config.API_BASE_URL}/api/auth/AddFactFR`,data)
            .then(res => { 
                if(res.data=='success'){
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre facture est bien ajoutée',
                  showConfirmButton: false,
                  timer: 1000 })
                  document.getElementById("formdata").reset();
                  this.attachments=[];
                  this.$router.push('/FactureFournisseurList')


                }else if(res.data=='Facture Existe'){
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Facture dupliquée',
                        text: 'Il existe déjà une facture avec ce numéro' })
                }else{
                      this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
                }
                
                })
      },
      addFacture(){
          if(this.FactureData.produits.length==0  || this.dateFact==''){
         this.$swal.fire({
              icon: 'error',
              title: 'Champs vides',
              text: 'Merci de remplire tous les champs' })
      }
      else if(this.FactureData.produits.length==1){
         for(let i=0;i<this.FactureData.produits.length;i++){
            if(this.FactureData.produits[i].Nom===null &&  this.FactureData.produits[i].id===0){
                  this.$swal.fire({
                  icon: 'error',
                  title: 'Pas de produit',
                  text: 'Merci d\'ajouter des produits avant de passer votre facture !' })
            }else{
              this.AddProd();
            }
        }
      }
      
      else if(this.FactureData.produits.length>0 ){
             this.AddProd();
        }

          //   let data = new FormData;
          // data.append('UserID', this.UserID.id);
          // data.append('products', JSON.stringify(this.FactureData.produits));
          // data.append('NumFact', this.NumFact);
          // data.append('fourniId', this.fourniId);
          // data.append('totalHT', this.totalHT);
          // data.append('totalNET', this.totalNET);
          // data.append('tva', this.tva);
          // data.append('somme', this.somme);
          // data.append('reglemment', this.reglemment);
          // data.append('dateFact', moment(String(this.dateFact)).format('DD/MM/YYYY'));
          //  for(let i=0; i<this.attachments.length;i++){
          //           data.append('attachments[]',this.attachments[i]);
          //       }
          // this.$http.post(`${config.API_BASE_URL}/api/auth/AddFactFR`,data)
          //   .then(res => { 
          //       if(res.data=='success'){
          //           this.$swal.fire({
          //         position: 'top-end',
          //         icon: 'success',
          //         title: 'Votre facture est bien ajoutée',
          //         showConfirmButton: false,
          //         timer: 1000 })
          //         document.getElementById("formdata").reset();
          //         this.attachments=[];
          //         this.$router.push('/FactureFournisseur')


          //       }else if(res.data=='Facture Existe'){
          //           this.$swal.fire({
          //               icon: 'error',
          //               title: 'Facture dupliquée',
          //               text: 'Il existe déjà une facture avec ce numéro' })
          //       }else{
          //             this.$swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Something went wrong! Pleaz try again'
          //     })
          //       }
                
          //       })

        
        //   AddFactFR
      },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
    this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.FactureData.produits.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      Nom: null,
      id:0,
      Prix_Achat: 0,
      qnt: 0,
    }
    
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val) => {
      const { Prix_Achat, id, Nom } = val
      FactureData.value.produits[index].Prix_Achat = Prix_Achat
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
    //   FactureData.value.produits[index].total = Prix_Achat*quantite
    }



    return {
      FactureData,
      invoiceData,
      clients,
      updateItemForm,
      itemFormBlankItem,
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
